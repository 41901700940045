<template>
  <a-spin class="loadingClass" v-if="dataValue.saveing === true" tip="正在保存模板 ..."></a-spin>

  <div v-if="dataValue.saveing === false" style="height: 100%;">
    <a-input placeholder="模板标题" style="margin-bottom: 20px;" v-model:value="dataValue.addInput.tempLabel"/>
    <a-input placeholder="邮件标题" v-model:value="dataValue.addInput.tempTitle"/>
    <a-textarea class="tInfo"
                v-model:value="dataValue.addInput.tempInfo"
                placeholder="模板内容"
    />
    <view class="bs">
      <view class="bs1">
        <a-input class="v1" placeholder="测试邮件" v-model:value="dataValue.testMail"/>
        <a-button class="v2" @click="allHttp.testSMS">测试</a-button>
      </view>

      <a-button class="bs2" type="primary" @click="allHttp.addSMS">{{ dataValue.buttonTitle }}</a-button>
    </view>
    <view class="ts">
      * 可使用变量：${company}，${user}，${model}。<br/>
    </view>
  </div>
</template>

<script>

import {reactive} from "vue";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";

export default {
  name : "template_mail_add",
  emits : [ 'goClose' ],
  props : [ 'row' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      saveing : false,
      buttonTitle : "",

      testMail : "",

      addInput : {
        tempId : 0,
        typeId : 2,
        tempLabel : "",
        tempTitle : "",
        tempInfo : ""
      }

    });

    if (props.row === null) {
      dataValue.buttonTitle = "添加邮件模板";
      dataValue.addInput.tempId = 0;
    } else {
      dataValue.buttonTitle = "修改邮件模板";
      dataValue.addInput.tempId = props.row['tempId'];
      dataValue.addInput.tempLabel = props.row['tempLabel'];
      dataValue.addInput.tempTitle = props.row['tempTitle'];
      dataValue.addInput.tempInfo = props.row['tempInfo'];
    }


    let allHttp = {


      testSMS : () => {
        if (dataValue.testMail === "") {
          message.error("请输入测试邮件地址");
        }

        function r(v){
          return v.replace( /\${company}/g, '闲芯公司').replace( /\${user}/g, '张先生').replace( /\${model}/g, 'AD7874anz');
        }

        let sendOneInput = {
          mail : dataValue.testMail,
          title : r(dataValue.addInput.tempTitle),
          info : r(dataValue.addInput.tempInfo)
        }

        httpRequest.post('/admin/ic7.mail/send.one', sendOneInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                message.info('发送成功！');
              }
            })

      },

      addSMS : () => {

        if (dataValue.addInput.tempTitle === "") {
          message.error("请输入标题");
        }

        if (dataValue.addInput.tempInfo === "") {
          message.error("请输入模板内容");
        }

        dataValue.saveing = true;

        httpRequest.post('/admin/set/ic7.template/add', dataValue.addInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                message.info('添加成功！');
                emit('goClose');
              }
            })
      }

    }

    return { dataValue, allHttp }
  }
}
</script>

<style scoped>
.tInfo { margin: 20px 0 20px 0; height: calc(100% - 230px); min-height: 100px; }
.ts { line-height: 30px; color: saddlebrown; clear: both; }
.bs { width: 100%; float: left;margin-bottom: 30px; }
.bs .bs1 {float: left}
.bs .bs1 .v1 {float: left; width: 350px; margin-right: 10px;}
.bs .bs1 .v2 {float: left}
.bs .bs2 {float: right;}
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>